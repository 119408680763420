import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue";
import _imports_0 from '@/assets/index/logo_w.png';
import _imports_1 from '@/assets/images/login_bg.png';
const _hoisted_1 = {
  class: "acea-row row-between-wrapper Logins"
};
const _hoisted_2 = {
  class: "inLogin acea-row row-center-wrapper"
};
const _hoisted_3 = {
  key: 0,
  class: "login"
};
const _hoisted_4 = {
  class: "login-title"
};
const _hoisted_5 = {
  class: "tabAddr acea-row row-left"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  class: "ml-13 mb-7"
};
const _hoisted_9 = {
  key: 1,
  class: "addForm"
};
const _hoisted_10 = {
  class: "ml-13 mb-7"
};
const _hoisted_11 = {
  class: "toConts acea-row row-left"
};
const _hoisted_12 = {
  class: "ml-13 mb-7"
};
const _hoisted_13 = {
  class: "mb-60 checks ml-13"
};
const _hoisted_14 = {
  style: {
    "color": "#999"
  }
};
const _hoisted_15 = {
  class: "login-foot__text"
};
const _hoisted_16 = {
  key: 1
};
const _hoisted_17 = {
  class: "showActibe"
};
const _hoisted_18 = {
  class: "title"
};
const _hoisted_19 = {
  class: "search"
};
const _hoisted_20 = {
  class: "content"
};
const _hoisted_21 = ["onClick"];
import { useUserStore } from '@/stores/user';
import { login } from '@/http';
import { ref, computed, onMounted } from 'vue';
import { showToast } from 'vant';
import { router } from '@/router';
import register from "@/view/register.vue";
import country from '@/utils/country.json';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'login',
  setup(__props) {
    const showLogins = ref(true);
    const showPwd = ref(false);
    const formData = ref({
      email: '',
      password: ''
    });
    const loading = ref(false);
    const userStore = useUserStore();
    const checked = ref(false);
    const CounTry = ref(country);
    onMounted(() => {
      let data = JSON.parse(window.localStorage.getItem('swaChecked'));
      console.log(data);
      if (data) {
        activeTab.value = data.type;
        checked.value = data.checked;
        formData.value.email = data.user.email;
        if (data.type !== 0) {
          formData.value.phone = removeFirstOccurrence(String(data.user.phone), String(data.user.phone_prefix));
          valueCode.value = data.user.phone_prefix;
        }
      }
    });
    const removeFirstOccurrence = (str, substr) => {
      const index = str.indexOf(substr);
      if (index === -1) {
        return str;
      }
      return str.substring(0, index) + str.substring(index + substr.length);
    };
    const _login = () => {
      loading.value = true;
      let data = {};
      if (activeTab.value === 0) {
        data = {
          ...formData.value,
          email: formData.value.email
        };
        delete data.phone;
      } else {
        data = {
          ...formData.value,
          phone: valueCode.value + formData.value.phone
        };
        delete data.email;
      }
      login(data).then(({
        code,
        msg,
        data
      }) => {
        loading.value = false;
        showToast(msg);
        if (code === 200) {
          if (checked.value) {
            let send = JSON.stringify({
              checked: checked.value,
              user: data.user,
              type: activeTab.value
            });
            window.localStorage.setItem('swaChecked', send);
          } else {
            window.localStorage.removeItem('swaChecked');
          }
          userStore.setToken(data.token);
          userStore.setUserinfo(data.user);
          router.push('/home?id=0');
          // setTimeout(()=>{
          //   window.location.reload()
          // },500)
        }
      }).catch(() => {
        loading.value = false;
      });
    };
    const show = ref(false);
    const {
      t
    } = useI18n();
    const tabList = ref([t('email'), t('Phone')]);
    const activeTab = ref(0);
    const changeTab = index => {
      activeTab.value = index;
      formData.value.password = '';
    };
    const value = ref();
    const valueCode = ref(66);
    const changeCode = item => {
      valueCode.value = item.country_code;
      show.value = false;
    };
    const pCodeKey = computed(() => {
      return {
        'en_US': 'country_name_en',
        'fr_FR': 'country_name_en',
        'es_ES': 'country_name_en',
        'it_IT': 'country_name_en',
        'en_TH': 'country_name_ta'
      }[userStore.lang];
    });
    // 不区分大小写的包含检查函数
    const includesIgnoreCase = (str, search) => {
      const regex = new RegExp(search, 'i');
      return str.match(regex);
    };
    const search = e => {
      let data = [];
      data = country.filter(v => includesIgnoreCase(v[pCodeKey.value], e));
      if (data.length <= 0) {
        data = country.filter(v => includesIgnoreCase(String(v.country_code), e));
        if (data.length <= 0) {
          data = country.filter(v => includesIgnoreCase(v.ab, e));
        }
      }
      CounTry.value = data;
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_checkbox = _resolveComponent("van-checkbox");
      const _component_van_button = _resolveComponent("van-button");
      const _component_el_link = _resolveComponent("el-link");
      const _component_van_search = _resolveComponent("van-search");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_cache[13] || (_cache[13] = _createElementVNode("div", {
        class: "left"
      }, [_createElementVNode("img", {
        class: "logo",
        src: _imports_0,
        alt: ""
      }), _createElementVNode("img", {
        class: "bg",
        src: _imports_1,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_2, [showLogins.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('login_account')), 1), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass({
            'active': index === activeTab.value
          }),
          onClick: $event => changeTab(index)
        }, _toDisplayString(item), 11, _hoisted_6);
      }), 256))]), activeTab.value === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('email')), 1), _createVNode(_component_van_field, {
        class: "input mb-20",
        modelValue: formData.value.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.email = $event),
        placeholder: _ctx.$t('input_email_account'),
        border: false
      }, null, 8, ["modelValue", "placeholder"])])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('Phone')), 1), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", {
        class: "code acea-row row-center-wrapper",
        onClick: _cache[1] || (_cache[1] = $event => show.value = true)
      }, [_createElementVNode("div", null, "+" + _toDisplayString(valueCode.value), 1), _createVNode(_component_van_icon, {
        name: "arrow-down",
        color: "#fff"
      })]), _createVNode(_component_van_field, {
        class: "input",
        modelValue: formData.value.phone,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.phone = $event),
        placeholder: _ctx.$t('input_phone_account'),
        border: false
      }, null, 8, ["modelValue", "placeholder"])])])), _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('password')), 1), _createVNode(_component_van_field, {
        class: "input mb-10",
        modelValue: formData.value.password,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.value.password = $event),
        type: showPwd.value ? 'text' : 'password',
        placeholder: _ctx.$t('input_login_password'),
        "right-icon": showPwd.value ? 'closed-eye' : 'eye-o',
        onClickRightIcon: _cache[4] || (_cache[4] = $event => showPwd.value = !showPwd.value),
        border: false
      }, null, 8, ["modelValue", "type", "placeholder", "right-icon"]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_van_checkbox, {
        modelValue: checked.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => checked.value = $event),
        "icon-size": "16",
        "checked-color": "rgb(86, 249, 170)"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("setUsername")), 1)]),
        _: 1
      }, 8, ["modelValue"])]), _createVNode(_component_van_button, {
        style: {
          "color": "#333"
        },
        class: "login-box__shadow mb-22",
        color: "#56F9AA",
        round: "",
        block: "",
        onClick: _cache[6] || (_cache[6] = $event => _login()),
        loading: loading.value
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('login')), 1)]),
        _: 1
      }, 8, ["loading"]), _createElementVNode("div", _hoisted_15, [_createTextVNode(_toDisplayString(_ctx.$t('no_account_yet')) + "？ ", 1), _createVNode(_component_el_link, {
        type: "primary",
        onClick: _cache[7] || (_cache[7] = $event => showLogins.value = false)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('register_now')), 1)]),
        _: 1
      })])])) : (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(register, {
        onReturnLogin: _cache[8] || (_cache[8] = $event => showLogins.value = true)
      })]))])]), _createVNode(_component_van_popup, {
        show: show.value,
        "onUpdate:show": _cache[10] || (_cache[10] = $event => show.value = $event),
        position: "center",
        round: "",
        onClose: _cache[11] || (_cache[11] = $event => show.value = false),
        onOpen: _cache[12] || (_cache[12] = $event => show.value = true)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('选择国家或地区')), 1), _createElementVNode("div", _hoisted_19, [_createVNode(_component_van_search, {
          modelValue: value.value,
          "onUpdate:modelValue": [_cache[9] || (_cache[9] = $event => value.value = $event), search],
          shape: "round",
          "left-icon": "",
          "right-icon": "search",
          placeholder: _ctx.$t('请输入搜索关键词')
        }, null, 8, ["modelValue", "placeholder"])]), _createElementVNode("div", _hoisted_20, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(CounTry.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "item acea-row row-between-wrapper",
            onClick: $event => changeCode(item)
          }, [_createElementVNode("div", null, _toDisplayString(item[pCodeKey.value]), 1), _createElementVNode("div", null, _toDisplayString(item.country_code), 1)], 8, _hoisted_21);
        }), 256))])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};